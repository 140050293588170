.bottomNavBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #ccc;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
}

.navButton {
    background: none;
    border: none;
    color: #62159e;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

.navButton svg {
    width: 24px;
    height: 24px;
}

.navButton span {
    margin-top: 2px;
    font-size: 12px;
}

.navButton:hover {
    color: #42b5fc;
}

.topNavBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1000;
}

/* Specific styles for iOS devices */
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), /* iPhone X, XS */
       (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3), /* iPhone XR, XS Max */
       (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3), /* iPhone 12, 12 Pro */
       (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3), /* iPhone 12 Pro Max */
       (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3), /* iPhone 15 */
       (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3)  /* iPhone 15 Pro Max */ {

    .topNavBar {
        height: 120px;
        padding-top: 50px;
    }

    .bottomNavBar {
        height: 100px;
        padding-bottom: 30px;

    }
}

.topNavBar .navButton {
    background: none;
    border: none;
    color: #62159e;
    display: flex;
    align-items: center;
    font-size: 24px;
}

.topNavBar .navButton svg {
    width: 32px;
    height: 32px;
}

.topNavBar .navButton:hover {
    color: #3c0d61;
}
.topNavBar .navButton:first-child {
    margin-left: 10px;
}

.topNavBar .navButton:last-child {
    margin-right: 10px;
}

.topNavBar .logoIcon {
    height: 50px;
}