form {
    background-color: #f8f9fa;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 500px;
}

label {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #666;
    text-align: left;
}

input[type="text"],
select,
input[type="email"],
input[type="password"],
input[type="date"],
input[type="time"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

input[type="text"]:focus,
select:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="time"]:focus {
    border-color: #80bdff;
    outline: none;
}

input[type="number"] {
    width: 50px;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

input[type="number"]:focus {
    border-color: #80bdff;
    outline: none;
}

.positionsFieldset {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
    border-radius: 8px;
}

.fieldsetLegend {
    font-weight: bold;
    color: #333;
    font-size: 1rem;
    padding-left: 5px;
    padding-right: 5px;
}

.positionCheckbox {
    display: block;
    margin: 5px 0;
}

.filter-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .text-filter-option {
    margin: 0 15px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #666;
    text-align: center;
  }

  .text-filter-option.active {
    font-weight: bold;
    color: #62159e;
  }

  .dropdown-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .filter-select {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .sortable-header {
    cursor: pointer;
  }



