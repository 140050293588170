h1, h2, h3 {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    color: #333;
    margin: 0 auto;
    width: 100%;
}

h1 {
    margin-bottom: 5px;
    font-size: 3.5rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 1.5rem;
    color: #888;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.3rem;
    }
}

/* Add glowing effect */
@keyframes glow {
    0% {
        text-shadow: 0 0 5px #b45cf7, 0 0 10px #b45cf7, 0 0 20px #b45cf7, 0 0 30px #b45cf7;
    }
    100% {
        text-shadow: 0 0 2px #b45cf7, 0 0 4px #b45cf7, 0 0 6px #b45cf7, 0 0 8px #b45cf7;
    }
}

.glowing {
    animation: glow 0.5s infinite alternate;
    color: #320951;
}

.link {
    color: #62159e;
    text-decoration: none;
}

.link:hover {
    color: #3c0d61;
    text-decoration: underline;
}

/* Success message styling */
.success {
    color: green;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  /* Error message styling */
  .error {
    color: red;
    margin-top: 10px;
    margin-bottom: 20px;
  }

/* Loading message styling */
.loading {
    color: rgb(105, 105, 105);
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .forgotPassword, .backToLogin {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    display: inline-block;
  }



  .recentWeekStatsLine {
    margin: 10px 0 0;
    padding: 0;
    color: #333;
    font-size: 1.2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.recentWeekStatsLine .highlight {
    font-weight: bold;
    color: #62159e;
    margin: 0 5px;
    white-space: nowrap; /* Prevent line breaks within stats */
}

.login {
    margin-bottom: 20px;
}